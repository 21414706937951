import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _df8616aa = () => interopDefault(import('../pages/activate.vue' /* webpackChunkName: "pages/activate" */))
const _7c00ddc6 = () => interopDefault(import('../pages/auth-failure.vue' /* webpackChunkName: "pages/auth-failure" */))
const _0a2a89d4 = () => interopDefault(import('../pages/auth-success.vue' /* webpackChunkName: "pages/auth-success" */))
const _48eb3d34 = () => interopDefault(import('../pages/blocklist/index.vue' /* webpackChunkName: "pages/blocklist/index" */))
const _6875b2e2 = () => interopDefault(import('../pages/crawler/index.vue' /* webpackChunkName: "pages/crawler/index" */))
const _61e8d60f = () => interopDefault(import('../pages/dashboard/index.vue' /* webpackChunkName: "pages/dashboard/index" */))
const _1b7039c2 = () => interopDefault(import('../pages/logout.vue' /* webpackChunkName: "pages/logout" */))
const _0edf13cd = () => interopDefault(import('../pages/test/index.vue' /* webpackChunkName: "pages/test/index" */))
const _6666c7c6 = () => interopDefault(import('../pages/user/index.vue' /* webpackChunkName: "pages/user/index" */))
const _6d822ff1 = () => interopDefault(import('../pages/crawler/recent/index.vue' /* webpackChunkName: "pages/crawler/recent/index" */))
const _4f12ccea = () => interopDefault(import('../pages/crawler/run/index.vue' /* webpackChunkName: "pages/crawler/run/index" */))
const _517ea639 = () => interopDefault(import('../pages/crawler/scheduledCrawls/index.vue' /* webpackChunkName: "pages/crawler/scheduledCrawls/index" */))
const _307eecb5 = () => interopDefault(import('../pages/user/license.vue' /* webpackChunkName: "pages/user/license" */))
const _10fdad5d = () => interopDefault(import('../pages/user/newPlan.vue' /* webpackChunkName: "pages/user/newPlan" */))
const _14e5ed5e = () => interopDefault(import('../pages/user/plans.vue' /* webpackChunkName: "pages/user/plans" */))
const _060a6ba5 = () => interopDefault(import('../pages/crawler/scheduledCrawls/create.vue' /* webpackChunkName: "pages/crawler/scheduledCrawls/create" */))
const _f6b95c1a = () => interopDefault(import('../pages/crawler/scheduledCrawls/edit.vue' /* webpackChunkName: "pages/crawler/scheduledCrawls/edit" */))
const _69e50ace = () => interopDefault(import('../pages/crawler/recent/_id.vue' /* webpackChunkName: "pages/crawler/recent/_id" */))
const _1938fa9a = () => interopDefault(import('../pages/crawler/run/_id.vue' /* webpackChunkName: "pages/crawler/run/_id" */))
const _34be87ca = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/sitecheck/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/activate",
    component: _df8616aa,
    name: "activate"
  }, {
    path: "/auth-failure",
    component: _7c00ddc6,
    name: "auth-failure"
  }, {
    path: "/auth-success",
    component: _0a2a89d4,
    name: "auth-success"
  }, {
    path: "/blocklist",
    component: _48eb3d34,
    name: "blocklist"
  }, {
    path: "/crawler",
    component: _6875b2e2,
    name: "crawler"
  }, {
    path: "/dashboard",
    component: _61e8d60f,
    name: "dashboard"
  }, {
    path: "/logout",
    component: _1b7039c2,
    name: "logout"
  }, {
    path: "/test",
    component: _0edf13cd,
    name: "test"
  }, {
    path: "/user",
    component: _6666c7c6,
    name: "user"
  }, {
    path: "/crawler/recent",
    component: _6d822ff1,
    name: "crawler-recent"
  }, {
    path: "/crawler/run",
    component: _4f12ccea,
    name: "crawler-run"
  }, {
    path: "/crawler/scheduledCrawls",
    component: _517ea639,
    name: "crawler-scheduledCrawls"
  }, {
    path: "/user/license",
    component: _307eecb5,
    name: "user-license"
  }, {
    path: "/user/newPlan",
    component: _10fdad5d,
    name: "user-newPlan"
  }, {
    path: "/user/plans",
    component: _14e5ed5e,
    name: "user-plans"
  }, {
    path: "/crawler/scheduledCrawls/create",
    component: _060a6ba5,
    name: "crawler-scheduledCrawls-create"
  }, {
    path: "/crawler/scheduledCrawls/edit",
    component: _f6b95c1a,
    name: "crawler-scheduledCrawls-edit"
  }, {
    path: "/crawler/recent/:id",
    component: _69e50ace,
    name: "crawler-recent-id"
  }, {
    path: "/crawler/run/:id",
    component: _1938fa9a,
    name: "crawler-run-id"
  }, {
    path: "/",
    component: _34be87ca,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
